<template>
  <div v-if="item">
    <v-row>
      <v-col cols="12" sm="12">
        <vx-card-list title="Basic info" :item="item" :tab="0">
          <v-list-item>
            <v-list-item-title> Id </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item._id)">
              {{ item._id }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Public URL </v-list-item-title>
            <v-list-item-subtitle>
              <a target="_blank" :href="$getPreviewURL(item.path)">{{ $getPreviewURL(item.path) }}</a>
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Name </v-list-item-title>
            <v-list-item-subtitle> {{ item.name }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Slug </v-list-item-title>
            <v-list-item-subtitle>{{ item.slug }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-if="item.slugs?.length">
            <v-list-item-title> Slugs </v-list-item-title>
            <v-list-item-subtitle>
              <div v-for="(slug, index) in item.slugs" :key="index">{{ slug }}</div>
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toView('page-schema', schema._id)">
            <v-list-item-title> Page schema </v-list-item-title>
            <v-list-item-subtitle>{{ schema.name }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list title="SEO" :item="item" :tab="1">
          <v-list-item>
            <v-list-item-title> Title </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.seo?.title }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Description </v-list-item-title>
            <v-list-item-subtitle class="white-space-normal">
              {{ item.seo?.description }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Image </v-list-item-title>
            <v-list-item-subtitle>
              <div class="my-2 w-50">
                <vx-img rounded rectangle preview :src="item.seo.image" />
              </div>
            </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list title="Style" :item="item" :tab="2">
          <v-list-item v-for="(value, key) in item.style" :key="key">
            <v-list-item-title> {{ key }} </v-list-item-title>
            <v-list-item-subtitle>
              {{ value }}
            </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>
      </v-col>

      <v-col cols="12" sm="12">
        <template v-for="(component, index) in schema.components">
          <vx-card-list
            v-if="component._isActive"
            :key="index"
            :title="component.name || component.schemaId.name"
            :item="item"
            :tab="index + 3"
          >
            <template v-for="(field, index) in component.schemaId.fields">
              <v-list-item :key="index">
                <v-list-item-title> {{ field.name }} </v-list-item-title>
                <v-list-item-subtitle class="white-space-normal">
                  <ul v-if="field.multiple && Array.isArray(field.value)">
                    <li v-for="(item, index) in field.value" :key="index" class="mb-2">
                      {{ item }}
                    </li>
                  </ul>
                  <vx-html v-else :value="field.value"></vx-html>
                </v-list-item-subtitle>
              </v-list-item>
            </template>
          </vx-card-list>
        </template>
      </v-col>

      <v-col cols="12">
        <vx-card-json :item="item" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { PageService, PageSchemaService } from '@tutti/services';
import { PageSchema } from '@tutti/models';

export default {
  data() {
    return {
      item: null,
      schema: {},
      count: {},
    };
  },
  async created() {
    await this.getPage();
    await this.getPageSchema();
  },
  methods: {
    async getPage() {
      const response = await PageService.getByIdAdmin(this.$route.params.id, { populateValues: true });
      if (response) {
        this.item = response.data;
        this.$setTitle(this.item.name);
      }
    },
    async getPageSchema() {
      const response = await PageSchemaService.getByIdAdmin(this.item.schemaId);
      if (response) {
        this.schema = new PageSchema(response.data);
        this.schema.setComponents(this.item.components, { isEdit: true });
      }
    },
  },
};
</script>
