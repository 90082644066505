var render = function () {
  var _vm$item$slugs, _vm$item$seo, _vm$item$seo2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "Basic info",
      "item": _vm.item,
      "tab": 0
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Id ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item._id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item._id) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Public URL ")]), _c('v-list-item-subtitle', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": _vm.$getPreviewURL(_vm.item.path)
    }
  }, [_vm._v(_vm._s(_vm.$getPreviewURL(_vm.item.path)))])])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Name ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.name) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Slug ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.item.slug) + " ")])], 1), (_vm$item$slugs = _vm.item.slugs) !== null && _vm$item$slugs !== void 0 && _vm$item$slugs.length ? _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Slugs ")]), _c('v-list-item-subtitle', _vm._l(_vm.item.slugs, function (slug, index) {
    return _c('div', {
      key: index
    }, [_vm._v(_vm._s(slug))]);
  }), 0)], 1) : _vm._e(), _c('v-list-item', {
    attrs: {
      "to": _vm.$toView('page-schema', _vm.schema._id)
    }
  }, [_c('v-list-item-title', [_vm._v(" Page schema ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.schema.name) + " ")])], 1)], 1), _c('vx-card-list', {
    attrs: {
      "title": "SEO",
      "item": _vm.item,
      "tab": 1
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Title ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s((_vm$item$seo = _vm.item.seo) === null || _vm$item$seo === void 0 ? void 0 : _vm$item$seo.title) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Description ")]), _c('v-list-item-subtitle', {
    staticClass: "white-space-normal"
  }, [_vm._v(" " + _vm._s((_vm$item$seo2 = _vm.item.seo) === null || _vm$item$seo2 === void 0 ? void 0 : _vm$item$seo2.description) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Image ")]), _c('v-list-item-subtitle', [_c('div', {
    staticClass: "my-2 w-50"
  }, [_c('vx-img', {
    attrs: {
      "rounded": "",
      "rectangle": "",
      "preview": "",
      "src": _vm.item.seo.image
    }
  })], 1)])], 1)], 1), _c('vx-card-list', {
    attrs: {
      "title": "Style",
      "item": _vm.item,
      "tab": 2
    }
  }, _vm._l(_vm.item.style, function (value, key) {
    return _c('v-list-item', {
      key: key
    }, [_c('v-list-item-title', [_vm._v(" " + _vm._s(key) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(value) + " ")])], 1);
  }), 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12"
    }
  }, [_vm._l(_vm.schema.components, function (component, index) {
    return [component._isActive ? _c('vx-card-list', {
      key: index,
      attrs: {
        "title": component.name || component.schemaId.name,
        "item": _vm.item,
        "tab": index + 3
      }
    }, [_vm._l(component.schemaId.fields, function (field, index) {
      return [_c('v-list-item', {
        key: index
      }, [_c('v-list-item-title', [_vm._v(" " + _vm._s(field.name) + " ")]), _c('v-list-item-subtitle', {
        staticClass: "white-space-normal"
      }, [field.multiple && Array.isArray(field.value) ? _c('ul', _vm._l(field.value, function (item, index) {
        return _c('li', {
          key: index,
          staticClass: "mb-2"
        }, [_vm._v(" " + _vm._s(item) + " ")]);
      }), 0) : _c('vx-html', {
        attrs: {
          "value": field.value
        }
      })], 1)], 1)];
    })], 2) : _vm._e()];
  })], 2), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vx-card-json', {
    attrs: {
      "item": _vm.item
    }
  })], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }